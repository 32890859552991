.disabled {
  pointer-events: none;
}

.collapsible-header {
  border: 1px solid transparent !important;
}

.collapsible-body {
  width: 100% !important;
}

.collapsible-body a {
  border: 1px solid transparent !important;
}

.text-align-right {
  text-align: right;
}

.no-padding-row {
  padding: 1px !important;
}

.no-padding-row td {
  padding: 1px !important;
}

.input-row-transparent {
  min-height: 100% !important;
  width: 99% !important;
  background: transparent;
  border: none;
  outline: none;
  resize: none;
  margin: 0;
  padding: 5px;
}

.overflowy-hidden {
  overflow-y: hidden;
}

.showcase {
  width: 100%;
  height: 400px;
  background: url("https://rimeim.com/files/icons/rimeim_portada.jpg") no-repeat
    center center / cover;
}

.text-danger {
  color: #d32f2f !important;
}

.text-center {
  text-align: center;
}

/* Icon size */
.m2x {
  font-size: 24px;
}
.m3x {
  font-size: 36px;
}
.m4x {
  font-size: 48px;
}

/* Container */
.small-container {
  padding-left: 1rem;
  padding-right: 1rem;
}

.horizontal-scroll-container {
  width: 100%;
  min-height: 220px;
  display: flex;
  overflow-x: auto;
  padding: 10px;
}

.horizontal-scroll-container .item {
  min-height: 210px;
  min-width: 210px;
  margin: 10px;
}

.horizontal-scroll-container .img-item {
  max-height: 210px !important;
  margin: 10px;
}

.horizontal-scroll-container .img-item .adjust {
  height: 210px !important;
}

.horizontal-scroll-container .item .close-button {
  width: 27px;
  height: 27px;
  padding: 1px;
  border-radius: 50%;
  float: right;
}

.card-distrubucion-productos .row .col {
  max-height: 40px;
}

/* Corner radios */
.border-radius-1 {
  border-radius: 6px;
}

.border-bottom {
  border-bottom: 1px solid #dddddd;
}

/* Color */
.text-white {
  color: white !important;
}

.bold {
  font-weight: bold;
}

/* Texto tamanio */
.font-size-1 {
  font-size: 1rem;
}

.break-work {
  word-wrap: break-word;
}

.color-link {
  color: rgb(0, 119, 255) !important;
}

.notifications-active {
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

.notifications-active-color,
.notifications-active {
  color: #e53935 !important;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

/* Altura */
.minh-100 {
  min-height: 100vh !important;
}

/* Largo */
.maxw-100 {
  max-width: 100%;
}

/* Margen */
.m-auto {
  margin: auto;
}

.ml-1 {
  margin-left: 0.5rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

/* Padding */
.p-1 {
  padding: 1rem !important;
}

.p-2 {
  padding: 2rem !important;
}

.p-3 {
  padding: 3rem !important;
}

.p-4 {
  padding: 4rem !important;
}

.pb-navbar {
  padding-bottom: 100px;
}

.px-2 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

/* Display */
.d-block {
  display: block;
}
.d-none {
  display: none;
}
.d-inline {
  display: inline;
}

.btn-block {
  display: block;
  width: 100%;
}

.p-relative {
  position: relative !important;
}

/* Textfield color */

/* label focus color */
/* .input-field input[type=text]:focus+label {
    color: green !important;
} */

/* label underline focus color */
.input-field input[type="text"]:focus,
.input-field input[type="password"]:focus {
  border-bottom: 1px solid #e53935 !important;
  box-shadow: 0 1px 0 0 #e53935 !important;
}

/* valid color */
/* .input-field input[type=text].valid {
    border-bottom: 1px solid #000;
    box-shadow: 0 1px 0 0 #000;
} */

/* invalid color */
/* .input-field input[type=text].invalid {
    border-bottom: 1px solid #000;
    box-shadow: 0 1px 0 0 #000;
} */

/* icon prefix focus color */
.input-field .prefix.active {
  color: #e53935;
}

.input-field .prefix {
  color: #9e9e9e;
}

.col-bordered .col {
  border: 1px solid #dddddd;
}

.p-y1 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.bordered {
  border: 1px solid #dddddd;
}

.header-th {
  height: 55px !important;
  width: 30px !important;
}

.checkbox-td {
  width: 30px !important;
  padding: 0 !important;
  padding-top: 1px !important;
}

/* Navbar - sidenav fixed */
header,
main,
footer {
  padding-left: 300px;
}

.normal-site {
  padding-left: 0px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.card a {
  color: black;
}

.card-product {
  overflow-y: hidden;
  max-height: 180px;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

/* Clases */
.left-bar {
  border: 1px solid rgb(184, 184, 184);
}

.venta-factura span,
.venta-factura table {
  margin-bottom: 0.7rem;
}

.logo-sidenav {
  height: 90%;
}

.checkbox-square {
  width: 20px;
  height: 20px;
  background-color: red;
}

/* Table */
.table-bordered td,
.table-bordered th {
  border: 1px solid #dddddd;
}

.mobile-only {
  display: none;
}

.provider-info span {
  margin-bottom: 1rem;
  font-size: 1.4rem;
}

.circle-local {
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.circle-element {
  border-radius: 50%;
}

@media only screen and (max-width: 992px) {
  header,
  main,
  footer {
    padding-left: 0;
  }

  .mobile-col1 {
    order: 1;
  }

  .mobile-col2 {
    order: 2;
  }
}

@media (min-width: 320px) and (max-width: 600px) {
  .mobile-only {
    display: block;
  }

  .table-cotizacion {
    font-size: 10px;
  }

  .table-cotizacion td.mxw-cotizacion {
    padding: 0;
    padding-left: 2px;
    overflow-wrap: break-word;
    word-break: break-all;
  }
}

.minw-100 {
  min-width: 100% !important;
}

.brand-logo {
  max-width: 90%;
}

/* Cards productos */
.row-flex {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.row-flex .flex-column {
  -ms-flex: 33.3333%; /* IE10 */
  flex: 33.3333%;
  max-width: 33.3333%;
  padding: 0 4px;
  margin-bottom: 8px;
}

.row-flex .flex-column .card {
  height: 100%;
}

.modal.modal-big {
  max-height: 100% !important;
  top: 10px !important;
}

.modal.modal-cotizacion {
  height: 95vh !important;
}
.modal.modal.modal-cotizacion .modal-content {
  height: 100%;
  overflow-y: hidden;
}

.modal.modal.modal-cotizacion .card {
  box-shadow: none;
}

.modal.modal.modal-cotizacion .card-content {
  height: calc(95vh - 65px) !important;
}

.modal.modal.modal-cotizacion .card-action {
  padding-bottom: 50px;
}

.fast-sale-input-products {
  border-right: 1px solid #dddddd;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80vh;
}

.fast-sale-products {
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 10px;
}

/* /////////////////////// */
.fast-sale-content {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fast-sale-table {
  flex: 1;
  height: 70vh;
  overflow-y: scroll;
}

.fast-sale-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dddddd;
  padding: 10px;
}

.fast-sale-total .fast-sale-value {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fast-sale-total .fast-sale-value:not(:last-child) {
  border-right: 1px solid #dddddd;
  margin-right: 10px;
}

.fast-sale-total .fast-sale-value .title {
  font-weight: bold;
  font-size: 15px;
}

.fast-sale-total .fast-sale-value .total {
  font-size: 22px;
  text-align: right;
  padding-right: 10px;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .row-flex .flex-column {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
}

.text-button-col {
  width: 100%;
  display: flex;
  align-items: center;
}

.text-button-col .input-field {
  flex: 1;
  margin-right: 1rem;
}

.btn-icon i {
  margin: 0;
}

.btn-icon-nav {
  padding: 5px 15px;
}

.btn-icon-nav i {
  line-height: 0 !important;
  height: max-content !important;
}

.btn-2-center {
  display: grid;
  align-items: center;
  grid-template-columns: 50% 50%;
  column-gap: 6px;
}

.card-title-normal {
  font-size: 1.2rem;
}

.card-title-action {
  display: flex !important;
  align-items: flex-start;
  justify-content: space-between;
}

.number-add-remove {
  display: flex;
  align-items: center;
}

.number-add-remove input {
  text-align: center;
}

.number-add-remove .input-field {
  flex: 1;
}

/* Color que indica en un texto que un valor fue incrementado */
.added-value {
  color: #2e7d32;
}

/* Color que indica en un texto que el valor descendio */
.minus-value {
  color: #bf360c;
}

/* Width 100% */
.w-100 {
  width: 100%;
}

/* Div con 2 botones */
.btn-2 {
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 5px;
}

/* Marca en negrita el color de un label en un check input */
.check-label-black label span {
  color: black;
}

.kardex-row-local {
  width: 12rem;
  max-width: 12rem;
}

.kardex-row-product {
  width: 20rem;
  max-width: 20rem;
}

.prefix-items {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
}

.prefix-items .prefix-item {
}


.prefix-items .prefix-item a {
  width: 100%;
  color: white;
  font-size: bold;
}

.table-bold {
  font-weight: bold;
}

/* Para telefonos */
@media (min-width: 6.25em) and (max-width: 37.5em) {
  .prefix-items {
    grid-template-columns: 1fr 1fr 1fr;
  }
};

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 200px) and (max-width: 600px) {
  #toast-container {
    bottom: 5% !important;
  }
  
}


/*
Actualizacion de posicion de header
*/
@media (min-width: 1281px){
  header .top-nav .nav-wrapper:not(.normal) .brand-logo {
    /* float: right !important; */
    right: 0;
  } 

  header .top-nav .nav-wrapper:not(.normal) ul {
    float: left !important;
  } 
}