.div_print_sale {
  font-size: 17px;
}

/* Impresion de venta */
.div_print_sale,
.div_print_sale .facturacion-footer,
.div_print_sale table,
.div_print_sale .cotizacion_cliente,
.div_print_sale .cotizacion_header {
  width: 1050px;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.text-bold {
  font-weight: bold;
}

.line_on_bottom {
  border-bottom: 2px solid gray;
}
.bottom_border {
  border-bottom: 1px solid gray;
}

.div_print_sale .cotizacion_header,
.div_print_sale .cotizacion_cliente {
  border: 2px solid gray;
  border-radius: 5px;
}

.div_print_sale .cotizacion_header {
  display: grid;
  grid-template-columns: 12rem 1fr;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
}

.div_print_sale .cotizacion_image img {
  width: 100%;
}

.div_print_sale .cotizacion_cliente {
  display: grid;
  grid-template-columns: 72% 28%;
}

.div_print_sale .cotizacion_cliente .cotizacion_cliente_info {
  padding: 0.5rem 0;
}

.div_print_sale .cotizacion_cliente .cotizacion_cliente_info .item {
  display: block;
}

.div_print_sale .cotizacion_cliente .cotizacion_cliente_info span {
  padding: 0 0.5rem;
}

.div_print_sale .cotizacion_cliente .cotizacion_cliente_info .cliente_info {
  display: grid;
  grid-template-columns: 50% 50%;
}

.div_print_sale .cotizacion_cliente_fecha {
  display: grid;
  grid-template-columns: repeat(3, max-content) 1fr;
}
.div_print_sale .cotizacion_cliente_fecha .casilla-value {
  outline: 1px solid gray;
  align-self: stretch;
}

.div_print_sale
  .cotizacion_cliente_fecha
  .casilla-value:not(:last-child)
  .title {
  border-right: 2px solid white;
}

.casilla-value {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  outline: none;
}
.casilla-value .title {
  background-color: black;
  color: white;
  text-align: center;
}
.casilla-value .value {
  padding: 0 0.5rem;
}

.div_print_sale table {
  border: 2px solid gray;
  margin-top: 0.5rem;
  border-radius: 5px !important;
}

.div_print_sale table th {
  background-color: black;
  color: white;
  text-align: center;
}
.div_print_sale .cant-th {
  width: 2rem;
}

.div_print_sale .datos_exonerados {
  display: flex;
  flex-direction: column;
}

.div_print_sale .datos_exonerados span:nth-child(1) {
  width: max-content;
  margin-bottom: 1rem;
}

.factura_datos {
  display: grid;
  grid-template-columns: 1fr 20% 10rem;
}
.factura_datos .factura_codigos .factura,
.factura_datos .factura_no,
.factura_datos .factura_tipo {
  font-size: 1.5rem;
}
.factura_datos .factura_codigos .factura {
  font-size: 15px;
}
.factura_datos .factura_codigos .factura span:nth-child(1) {
  margin-right: 5px;
}

.impresion_titulo {
  font-size: 0.7rem;
  font-weight: bold;
  text-transform: uppercase;
}

.factura_detalle .factura_datos {
  padding-top: 1rem;
}

.factura_datos .factura_no {
  color: red;
}

.factura_datos .rango_autorizado {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 0.5rem;
  text-align: center;
  max-width: 85%;
}

.rango_autorizado .value {
  font-size: 0.9rem;
}

.factura_datos .factura_tipo .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.check_box {
  border: 1px solid gray;
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.factura_footer {
  margin-top: -10px;
  text-align: right;
  font-size: 15px;
}

.factura_footer .message {
  font-style: italic;
}

.factura_footer .cai {
  text-transform: uppercase;
  font-weight: bold;
}

.facturacion-footer {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}

.facturacion-footer .valor-letras {
  display: flex;
  width: calc(1050px - 18rem);
}

.facturacion-footer .valor-letras .text {
  width: max-content;
  margin-right: 5px;
}

.valor-letras .line {
  border-bottom: 1px solid gray;
  flex: 1;
}

.facturacion-footer .message {
  text-transform: uppercase;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 0 0.5rem;
  width: 18rem;
}

.div_print_sale .table-venta td {
  padding: 5px;
}
