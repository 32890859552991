.disabled {
  pointer-events: none;
}

.collapsible-header {
  border: 1px solid transparent !important;
}

.collapsible-body {
  width: 100% !important;
}

.collapsible-body a {
  border: 1px solid transparent !important;
}

.text-align-right {
  text-align: right;
}

.no-padding-row {
  padding: 1px !important;
}

.no-padding-row td {
  padding: 1px !important;
}

.input-row-transparent {
  min-height: 100% !important;
  width: 99% !important;
  background: transparent;
  border: none;
  outline: none;
  resize: none;
  margin: 0;
  padding: 5px;
}

.overflowy-hidden {
  overflow-y: hidden;
}

.showcase {
  width: 100%;
  height: 400px;
  background: url("https://rimeim.com/files/icons/rimeim_portada.jpg") no-repeat
    center center / cover;
}

.text-danger {
  color: #d32f2f !important;
}

.text-center {
  text-align: center;
}

/* Icon size */
.m2x {
  font-size: 24px;
}
.m3x {
  font-size: 36px;
}
.m4x {
  font-size: 48px;
}

/* Container */
.small-container {
  padding-left: 1rem;
  padding-right: 1rem;
}

.horizontal-scroll-container {
  width: 100%;
  min-height: 220px;
  display: flex;
  overflow-x: auto;
  padding: 10px;
}

.horizontal-scroll-container .item {
  min-height: 210px;
  min-width: 210px;
  margin: 10px;
}

.horizontal-scroll-container .img-item {
  max-height: 210px !important;
  margin: 10px;
}

.horizontal-scroll-container .img-item .adjust {
  height: 210px !important;
}

.horizontal-scroll-container .item .close-button {
  width: 27px;
  height: 27px;
  padding: 1px;
  border-radius: 50%;
  float: right;
}

.card-distrubucion-productos .row .col {
  max-height: 40px;
}

/* Corner radios */
.border-radius-1 {
  border-radius: 6px;
}

.border-bottom {
  border-bottom: 1px solid #dddddd;
}

/* Color */
.text-white {
  color: white !important;
}

.bold {
  font-weight: bold;
}

/* Texto tamanio */
.font-size-1 {
  font-size: 1rem;
}

.break-work {
  word-wrap: break-word;
}

.color-link {
  color: rgb(0, 119, 255) !important;
}

.notifications-active {
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

.notifications-active-color,
.notifications-active {
  color: #e53935 !important;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@keyframes ring {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  1% {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }

  3% {
    -webkit-transform: rotate(-28deg);
            transform: rotate(-28deg);
  }

  5% {
    -webkit-transform: rotate(34deg);
            transform: rotate(34deg);
  }

  7% {
    -webkit-transform: rotate(-32deg);
            transform: rotate(-32deg);
  }

  9% {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }

  11% {
    -webkit-transform: rotate(-28deg);
            transform: rotate(-28deg);
  }

  13% {
    -webkit-transform: rotate(26deg);
            transform: rotate(26deg);
  }

  15% {
    -webkit-transform: rotate(-24deg);
            transform: rotate(-24deg);
  }

  17% {
    -webkit-transform: rotate(22deg);
            transform: rotate(22deg);
  }

  19% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }

  21% {
    -webkit-transform: rotate(18deg);
            transform: rotate(18deg);
  }

  23% {
    -webkit-transform: rotate(-16deg);
            transform: rotate(-16deg);
  }

  25% {
    -webkit-transform: rotate(14deg);
            transform: rotate(14deg);
  }

  27% {
    -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg);
  }

  29% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }

  31% {
    -webkit-transform: rotate(-8deg);
            transform: rotate(-8deg);
  }

  33% {
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg);
  }

  35% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }

  37% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }

  39% {
    -webkit-transform: rotate(-1deg);
            transform: rotate(-1deg);
  }

  41% {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg);
  }

  43% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}

/* Altura */
.minh-100 {
  min-height: 100vh !important;
}

/* Largo */
.maxw-100 {
  max-width: 100%;
}

/* Margen */
.m-auto {
  margin: auto;
}

.ml-1 {
  margin-left: 0.5rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

/* Padding */
.p-1 {
  padding: 1rem !important;
}

.p-2 {
  padding: 2rem !important;
}

.p-3 {
  padding: 3rem !important;
}

.p-4 {
  padding: 4rem !important;
}

.pb-navbar {
  padding-bottom: 100px;
}

.px-2 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

/* Display */
.d-block {
  display: block;
}
.d-none {
  display: none;
}
.d-inline {
  display: inline;
}

.btn-block {
  display: block;
  width: 100%;
}

.p-relative {
  position: relative !important;
}

/* Textfield color */

/* label focus color */
/* .input-field input[type=text]:focus+label {
    color: green !important;
} */

/* label underline focus color */
.input-field input[type="text"]:focus,
.input-field input[type="password"]:focus {
  border-bottom: 1px solid #e53935 !important;
  box-shadow: 0 1px 0 0 #e53935 !important;
}

/* valid color */
/* .input-field input[type=text].valid {
    border-bottom: 1px solid #000;
    box-shadow: 0 1px 0 0 #000;
} */

/* invalid color */
/* .input-field input[type=text].invalid {
    border-bottom: 1px solid #000;
    box-shadow: 0 1px 0 0 #000;
} */

/* icon prefix focus color */
.input-field .prefix.active {
  color: #e53935;
}

.input-field .prefix {
  color: #9e9e9e;
}

.col-bordered .col {
  border: 1px solid #dddddd;
}

.p-y1 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.bordered {
  border: 1px solid #dddddd;
}

.header-th {
  height: 55px !important;
  width: 30px !important;
}

.checkbox-td {
  width: 30px !important;
  padding: 0 !important;
  padding-top: 1px !important;
}

/* Navbar - sidenav fixed */
header,
main,
footer {
  padding-left: 300px;
}

.normal-site {
  padding-left: 0px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.card a {
  color: black;
}

.card-product {
  overflow-y: hidden;
  max-height: 180px;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

/* Clases */
.left-bar {
  border: 1px solid rgb(184, 184, 184);
}

.venta-factura span,
.venta-factura table {
  margin-bottom: 0.7rem;
}

.logo-sidenav {
  height: 90%;
}

.checkbox-square {
  width: 20px;
  height: 20px;
  background-color: red;
}

/* Table */
.table-bordered td,
.table-bordered th {
  border: 1px solid #dddddd;
}

.mobile-only {
  display: none;
}

.provider-info span {
  margin-bottom: 1rem;
  font-size: 1.4rem;
}

.circle-local {
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.circle-element {
  border-radius: 50%;
}

@media only screen and (max-width: 992px) {
  header,
  main,
  footer {
    padding-left: 0;
  }

  .mobile-col1 {
    order: 1;
  }

  .mobile-col2 {
    order: 2;
  }
}

@media (min-width: 320px) and (max-width: 600px) {
  .mobile-only {
    display: block;
  }

  .table-cotizacion {
    font-size: 10px;
  }

  .table-cotizacion td.mxw-cotizacion {
    padding: 0;
    padding-left: 2px;
    overflow-wrap: break-word;
    word-break: break-all;
  }
}

.minw-100 {
  min-width: 100% !important;
}

.brand-logo {
  max-width: 90%;
}

/* Cards productos */
.row-flex { /* IE10 */
  display: flex; /* IE10 */
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.row-flex .flex-column { /* IE10 */
  flex: 33.3333% 1;
  max-width: 33.3333%;
  padding: 0 4px;
  margin-bottom: 8px;
}

.row-flex .flex-column .card {
  height: 100%;
}

.modal.modal-big {
  max-height: 100% !important;
  top: 10px !important;
}

.modal.modal-cotizacion {
  height: 95vh !important;
}
.modal.modal.modal-cotizacion .modal-content {
  height: 100%;
  overflow-y: hidden;
}

.modal.modal.modal-cotizacion .card {
  box-shadow: none;
}

.modal.modal.modal-cotizacion .card-content {
  height: calc(95vh - 65px) !important;
}

.modal.modal.modal-cotizacion .card-action {
  padding-bottom: 50px;
}

.fast-sale-input-products {
  border-right: 1px solid #dddddd;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80vh;
}

.fast-sale-products {
  flex: 1 1;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 10px;
}

/* /////////////////////// */
.fast-sale-content {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fast-sale-table {
  flex: 1 1;
  height: 70vh;
  overflow-y: scroll;
}

.fast-sale-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dddddd;
  padding: 10px;
}

.fast-sale-total .fast-sale-value {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fast-sale-total .fast-sale-value:not(:last-child) {
  border-right: 1px solid #dddddd;
  margin-right: 10px;
}

.fast-sale-total .fast-sale-value .title {
  font-weight: bold;
  font-size: 15px;
}

.fast-sale-total .fast-sale-value .total {
  font-size: 22px;
  text-align: right;
  padding-right: 10px;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .row-flex .flex-column {
    flex: 100% 1;
    max-width: 100%;
  }
}

.text-button-col {
  width: 100%;
  display: flex;
  align-items: center;
}

.text-button-col .input-field {
  flex: 1 1;
  margin-right: 1rem;
}

.btn-icon i {
  margin: 0;
}

.btn-icon-nav {
  padding: 5px 15px;
}

.btn-icon-nav i {
  line-height: 0 !important;
  height: -webkit-max-content !important;
  height: max-content !important;
}

.btn-2-center {
  display: grid;
  align-items: center;
  grid-template-columns: 50% 50%;
  grid-column-gap: 6px;
  -webkit-column-gap: 6px;
          column-gap: 6px;
}

.card-title-normal {
  font-size: 1.2rem;
}

.card-title-action {
  display: flex !important;
  align-items: flex-start;
  justify-content: space-between;
}

.number-add-remove {
  display: flex;
  align-items: center;
}

.number-add-remove input {
  text-align: center;
}

.number-add-remove .input-field {
  flex: 1 1;
}

/* Color que indica en un texto que un valor fue incrementado */
.added-value {
  color: #2e7d32;
}

/* Color que indica en un texto que el valor descendio */
.minus-value {
  color: #bf360c;
}

/* Width 100% */
.w-100 {
  width: 100%;
}

/* Div con 2 botones */
.btn-2 {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
}

/* Marca en negrita el color de un label en un check input */
.check-label-black label span {
  color: black;
}

.kardex-row-local {
  width: 12rem;
  max-width: 12rem;
}

.kardex-row-product {
  width: 20rem;
  max-width: 20rem;
}

.prefix-items {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.prefix-items .prefix-item {
}


.prefix-items .prefix-item a {
  width: 100%;
  color: white;
  font-size: bold;
}

.table-bold {
  font-weight: bold;
}

/* Para telefonos */
@media (min-width: 6.25em) and (max-width: 37.5em) {
  .prefix-items {
    grid-template-columns: 1fr 1fr 1fr;
  }
};

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 200px) and (max-width: 600px) {
  #toast-container {
    bottom: 5% !important;
  }
  
}


/*
Actualizacion de posicion de header
*/
@media (min-width: 1281px){
  header .top-nav .nav-wrapper:not(.normal) .brand-logo {
    /* float: right !important; */
    right: 0;
  } 

  header .top-nav .nav-wrapper:not(.normal) ul {
    float: left !important;
  } 
}
.special-input {
  height: 100% !important;
  width: 99% !important;
  background: transparent;
  border: none;
  outline: none;
}

.td-with-input {
  height: 32px !important;
  margin: 0 !important;
  padding: 5px !important;
  overflow-x: hidden;
  overflow-y: hidden;
}

.active-tr-sell {
  background-color: #bbdefb !important;
}

.active-tr-sell-error {
  background-color: #ffcdd2 !important;
}

.venta-productos {
  margin-bottom: 0;
}

.venta-productos .col {
  height: calc(100vh - 64px - 70px);
}

.venta-total {
  height: 70px;
  margin-bottom: 0;
}

.venta-total .col {
  height: 70px;
}

.venta-total .col p {
  margin: 0;
}

.venta-total .col .total-number {
  font-size: 20px;
  bottom: 0;
  float: right;
}

/* Table */
.header-fixed {
  width: 100%;
}

.header-fixed > thead,
.header-fixed > tbody,
.header-fixed > thead > tr,
.header-fixed > tbody > tr,
.header-fixed > thead > tr > th,
.header-fixed > tbody > tr > td {
  display: block;
}

.header-fixed > tbody > tr:after,
.header-fixed > thead > tr:after {
  content: "  ";
  display: block;
  visibility: hidden;
  clear: both;
}

.header-fixed > tbody {
  scrollbar-width: none;
  overflow-y: auto;
  height: calc(100vh - 64px - 70px - 54px) !important;
}

.header-fixed > tbody > tr > td,
.header-fixed > thead > tr > th {
  width: 25% !important;
  float: left;
}

.header-fixed > thead > tr > th {
  white-space: nowrap;
}

/* Cotizacion */
.div_reporte {
  width: 1050px !important;
  background-color: white;
  font-size: 15px;
}

#div_print_cotizacion tbody th:nth-child(2) {
  max-width: 50px;
}

#div_print_cotizacion tbody th:nth-child(3) {
  max-width: 50px;
}

#div_print_cotizacion tbody th:nth-child(4) {
  max-width: 60px;
}

.firma-comprobante .line {
  border-bottom: 1px solid black;
  width: 300px;
  margin-bottom: 5px;
}

.cotizacion-comprobante.factura {
  margin-top: 4rem;
}

.firma-producto-entregado {
  color: #d6d600d7;
  font-weight: bold;
  margin-top: 5px;
}

.cotizacion_header {
  text-align: center;
}

.cotizacion_header .d-block {
  margin-bottom: 2px;
  padding: 0;
  line-height: 15px;
}

.div_print_sale {
  font-size: 17px;
}

/* Impresion de venta */
.div_print_sale,
.div_print_sale .facturacion-footer,
.div_print_sale table,
.div_print_sale .cotizacion_cliente,
.div_print_sale .cotizacion_header {
  width: 1050px;
  print-color-adjust: exact;
}

.text-bold {
  font-weight: bold;
}

.line_on_bottom {
  border-bottom: 2px solid gray;
}
.bottom_border {
  border-bottom: 1px solid gray;
}

.div_print_sale .cotizacion_header,
.div_print_sale .cotizacion_cliente {
  border: 2px solid gray;
  border-radius: 5px;
}

.div_print_sale .cotizacion_header {
  display: grid;
  grid-template-columns: 12rem 1fr;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
}

.div_print_sale .cotizacion_image img {
  width: 100%;
}

.div_print_sale .cotizacion_cliente {
  display: grid;
  grid-template-columns: 72% 28%;
}

.div_print_sale .cotizacion_cliente .cotizacion_cliente_info {
  padding: 0.5rem 0;
}

.div_print_sale .cotizacion_cliente .cotizacion_cliente_info .item {
  display: block;
}

.div_print_sale .cotizacion_cliente .cotizacion_cliente_info span {
  padding: 0 0.5rem;
}

.div_print_sale .cotizacion_cliente .cotizacion_cliente_info .cliente_info {
  display: grid;
  grid-template-columns: 50% 50%;
}

.div_print_sale .cotizacion_cliente_fecha {
  display: grid;
  grid-template-columns: repeat(3, -webkit-max-content) 1fr;
  grid-template-columns: repeat(3, max-content) 1fr;
}
.div_print_sale .cotizacion_cliente_fecha .casilla-value {
  outline: 1px solid gray;
  align-self: stretch;
}

.div_print_sale
  .cotizacion_cliente_fecha
  .casilla-value:not(:last-child)
  .title {
  border-right: 2px solid white;
}

.casilla-value {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  outline: none;
}
.casilla-value .title {
  background-color: black;
  color: white;
  text-align: center;
}
.casilla-value .value {
  padding: 0 0.5rem;
}

.div_print_sale table {
  border: 2px solid gray;
  margin-top: 0.5rem;
  border-radius: 5px !important;
}

.div_print_sale table th {
  background-color: black;
  color: white;
  text-align: center;
}
.div_print_sale .cant-th {
  width: 2rem;
}

.div_print_sale .datos_exonerados {
  display: flex;
  flex-direction: column;
}

.div_print_sale .datos_exonerados span:nth-child(1) {
  width: -webkit-max-content;
  width: max-content;
  margin-bottom: 1rem;
}

.factura_datos {
  display: grid;
  grid-template-columns: 1fr 20% 10rem;
}
.factura_datos .factura_codigos .factura,
.factura_datos .factura_no,
.factura_datos .factura_tipo {
  font-size: 1.5rem;
}
.factura_datos .factura_codigos .factura {
  font-size: 15px;
}
.factura_datos .factura_codigos .factura span:nth-child(1) {
  margin-right: 5px;
}

.impresion_titulo {
  font-size: 0.7rem;
  font-weight: bold;
  text-transform: uppercase;
}

.factura_detalle .factura_datos {
  padding-top: 1rem;
}

.factura_datos .factura_no {
  color: red;
}

.factura_datos .rango_autorizado {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 0.5rem;
  text-align: center;
  max-width: 85%;
}

.rango_autorizado .value {
  font-size: 0.9rem;
}

.factura_datos .factura_tipo .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.check_box {
  border: 1px solid gray;
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.factura_footer {
  margin-top: -10px;
  text-align: right;
  font-size: 15px;
}

.factura_footer .message {
  font-style: italic;
}

.factura_footer .cai {
  text-transform: uppercase;
  font-weight: bold;
}

.facturacion-footer {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}

.facturacion-footer .valor-letras {
  display: flex;
  width: calc(1050px - 18rem);
}

.facturacion-footer .valor-letras .text {
  width: -webkit-max-content;
  width: max-content;
  margin-right: 5px;
}

.valor-letras .line {
  border-bottom: 1px solid gray;
  flex: 1 1;
}

.facturacion-footer .message {
  text-transform: uppercase;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 0 0.5rem;
  width: 18rem;
}

.div_print_sale .table-venta td {
  padding: 5px;
}

.cotizacion-footer {
  text-align: right;
  margin-right: 5px;
}

.cotizacion-productos {
  margin-bottom: 0;
}

.cotizacion-productos .col {
  height: calc(100vh - 64px - 70px - 100px);
}

.venta-total {
  height: 70px;
  margin-bottom: 0;
}

.venta-total .col {
  height: 70px;
}

.venta-total .col p {
  margin: 0;
}

.venta-total .col .total-number {
  font-size: 20px;
  bottom: 0;
  float: right;
}

/* Table */
.header-fixed {
  width: 100%;
}

.header-fixed > thead,
.header-fixed > tbody,
.header-fixed > thead > tr,
.header-fixed > tbody > tr,
.header-fixed > thead > tr > th,
.header-fixed > tbody > tr > td {
  display: block;
}

.header-fixed > tbody > tr:after,
.header-fixed > thead > tr:after {
  content: '  ';
  display: block;
  visibility: hidden;
  clear: both;
}

.header-fixed > tbody {
  scrollbar-width: none;
  overflow-y: auto;
  height: calc(100vh - 64px - 70px - 155px);
}

.header-fixed > tbody > tr > td,
.header-fixed > thead > tr > th {
  width: 25%;
  float: left;
}

.header-fixed > thead > tr > th {
  white-space: nowrap;
}

.print-rtn-date {
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.print-rtn-date span {
  display: block !important;
  width: -webkit-max-content !important;
  width: max-content !important;
}
.checkbox-td label span {
    display: none;
    /* margin: 0 !important; */
    padding: 12px !important;
}

.devolucion {
    margin-bottom: 0;
}

.devolucion .col {
    height: calc(100vh - 64px - 70px - 96px);
}

.devolucion-total {
    height: 70px;
    margin-bottom: 0;
}

.devolucion-total .col {
    height: 70px;
}

.devolucion-total .col p {
    margin: 0;
}

.devolucion-total .col .total-number {
    font-size: 20px;
    bottom: 0;
    float: right;
}

/* Table */
.header-fixed {
    width: 100%
}

.header-fixed>thead,
.header-fixed>tbody,
.header-fixed>thead>tr,
.header-fixed>tbody>tr,
.header-fixed>thead>tr>th,
.header-fixed>tbody>tr>td {
    display: block;
}

.header-fixed>tbody>tr:after,
.header-fixed>thead>tr:after {
    content: '  ';
    display: block;
    visibility: hidden;
    clear: both;
}

.header-fixed>tbody {
    scrollbar-width: none;
    overflow-y: auto;
    height: calc(100vh - 64px - 70px - 54px - 96px);
}

.header-fixed>tbody>tr>td,
.header-fixed>thead>tr>th {
    width: calc(100% / 4 - 10px);
    float: left;
}

.header-fixed>thead>tr>th {
    white-space: nowrap;
}

.header-th {
    height: 55px !important;
    width: 30px !important;
}

.checkbox-td {
    width: 30px !important;
    padding: 0 !important;
    padding-top: 1px !important;
}

.checkbox-td label {
    margin-left: 4px;
    padding-top: 5px !important;
}
